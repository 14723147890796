<template>
  <div class="box">
    <van-swipe :autoplay="3000" @change="onChange">
      <van-swipe-item
        v-for="item in list.OriGoodsImgs.split(',')"
        :key="item + 'aa'"
      >
        <img :src="item" style="width: 100%; height: 249px" />
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">
          {{ count + 1 }}/{{ list.OriGoodsImgs.split(",").length }}
        </div>
      </template>
    </van-swipe>
    <div class="mid">
      <div style="display: flex; justify-content: space-between">
        <div class="title">{{ list.OriGoodsName }}</div>
        <div class="type">{{ list.OriGoodsTypeName }}</div>
      </div>
      <div class="card">
        <div class="card1">
          <span style="display: block; margin-bottom: 8px">
            <img
              src="https://dximg.dexian.ren/tyzhsq/charger.png"
              style="width: 18px; vertical-align: middle"
            />
            <span v-if="list.IssNickname && list.IssNickname != ''">
              {{ list.IssNickname }}</span
            >
            <span v-else> 匿名</span>
          </span>
          <span style="display: block">
            <img
              src="https://dximg.dexian.ren/tyzhsq/touchTel.png"
              style="width: 18px; vertical-align: middle"
            />
            {{ list.IssMobile }}
          </span>
        </div>
        <div class="card2">
          <span>产品说明：</span
          ><i
            class="van-multi-ellipsis--l2"
            style="font-style: normal; font-weight: normal"
            >{{ list.OriGoodsDesc }}</i
          >
        </div>
      </div>
    </div>
    <div class="exmid">
      <div style="display: flex; justify-content: space-between">
        <div class="title">{{ list.ExcGoodsName }}</div>
        <div class="type">{{ list.ExcGoodsTypeName }}</div>
      </div>
      <div class="title" style="margin-top: 15px">交换需求</div>
      <div class="des">{{ list.ExcDemand }}</div>
      <div class="title" style="margin-top: 15px">备注</div>
      <div class="des">{{ list.Remark }}</div>
    </div>
    <div class="exmid">
      <div class="title">物品照片</div>
      <div
        v-for="(item, index) in list.OriGoodsImgs.split(',')"
        :key="index"
        style="text-align: center"
      >
        <img
          :src="item"
          alt=""
          style="width: 95%; border-radius: 8px; margin-top: 11px"
          @click="thematic(list.OriGoodsImgs.split(','))"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import { WxGetExchange } from "@/api/RealInfo";
export default {
  // 组件名称
  name: "",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {},

  // 组件状态值
  data() {
    return {
      list: {},
      count: "",
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  mounted() {
    this.getExchange();
  },
  // 组件方法
  methods: {
    thematic(e) {
      ImagePreview({
        images: e, // 传递的是图片集数组
        startPosition: 0, // 图片索引位置
        closeable: true, // 是否显示关了按钮
        showIndicators: true, // 是否显示轮播指示器
        loop: false, // 不设置为false,首图和尾部图不能正常加载
      });
    },
    async getExchange() {
      const res = await WxGetExchange({
        exchangeId: this.$route.params.ExchangeId,
      });
      console.log(res);
      this.list = res.data.data;
    },
    onChange(index) {
      this.count = index;
    },
  },
  // 以下是生命周期钩子  注：没用到的钩子请自行删除
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {},
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
};
</script> 

<style scoped >
.box {
  background: #f9f9f9;
}
.custom-indicator {
  position: absolute;
  right: 0px;
  bottom: 60px;
  padding: 4px 15px;
  font-size: 14px;
  font-family: PingFang SC;
  color: #ffffff;
  background: #00000045;
  border-radius: 15px 0px 0px 15px;
  text-align: center;
  line-height: 20px;
}

.mid {
  overflow: hidden;
  width: 95%;
  height: 145px;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  margin: -50px auto;
  margin-bottom: 11px;
}
.title {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1e1e1e;
  margin-top: 19px;
  margin-left: 12px;
}

.type {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #6f6f6f;
  margin-top: 19px;
  margin-right: 15px;
}

.card {
  margin-top: 14px;
  display: flex;
  justify-content: space-evenly;
}
.card div {
  height: 76px;
  background: #f9fcff;
  border-radius: 8px;
}

.card1 {
  width: 36%;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  box-sizing: border-box;
  padding: 16px 8px;
}
.card2 {
  width: 58%;
  box-sizing: border-box;
  padding: 13px 10px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #6f6f6f;
}
.card2 span {
  color: #1e1e1e;
}

.exmid {
  overflow: hidden;
  width: 95%;
  margin: auto;
  /* height: 195px; */
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 11px;
  box-sizing: border-box;
  padding-bottom: 19px;
}

.des {
  margin-left: 12px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-top: 5px;
}
</style>